export const API_ENDPOINT = "https://api.mms.uat.4ps.dev/mms/api";
export const API_FEEDBACK = "";
export const API_ENDPOINT_BO_DELI = "https://uat-deli-v2.pizza4ps.io";
export const API_KMS = "https://vn-kms.uat.pizza4ps.io/api/kvs";

export const firebaseConfig = {
   apiKey: "AIzaSyDbFXVnMR3zoq3UT2U8D5lU8yEJiLcVpSA",
   authDomain: "map-4ps-prod.firebaseapp.com",
   databaseURL: "map-4ps-prod.firebaseapp.com",
   projectId: "map-4ps-prod",
   storageBucket: "map-4ps-prod.appspot.com",
   messagingSenderId: "1074448014141",
   appId: "1:1074448014141:web:964da611c796d5c6518354",
   measurementId: "G-8B4C5LN45B",
};

export const VAPI_KEY_FIREBASE = "BMuW1RSkk5wE5OAfNaKCheNLpZoj5PQJJ0KT_8AFtO-9IGt4nSr-HJ_4kCzZz194kd3_RDm75BZlkoh1sZoVDD0";


export const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm";

export const DATE_FORMAT = "DD/MM/YYYY";

export const UNLIMITED_ADD_ON_SELECTION = -1;

export const FILTER_TYPE = {
   SELECT: "select",
};

export const ADD_ON_TYPE = {
   COMBO: 0,
   SINGLE: 1,
   MULTIPLE: 2,
};

export const MAX_SELECTION_ADD_ON_TYPE = {
   UNLIMITED: "unlimited",
   LIMIT: "limit",
};

export const ADD_ON_TYPE_NAME = {
   COMBO: "Combo",
   SINGLE: "Required",
   MULTIPLE: "Optional",
};

export const COUNTRY_CODE = {
   VN: "VN",
   IN: "IN",
   JP: "JP",
};

export const COUNTRY_NAME = {
   VN: "Vietnam",
   IN: "India",
   JP: "Japan",
};